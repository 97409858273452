
import { LitElement, html, css } from 'lit';
import './cookie_popup'

class CrowdfundingBottomBanner extends LitElement {
  static properties = {
    isOpen: { type: Boolean, reflect: true, attribute: false },
    bannerHeight: { type: Number, reflect: true, attribute: false },
    cookiePopupHeight: { type: Number, reflect: true, attribute: false }
  }
  static styles = css`
    .cookie-popup {
      position: absolute;
      width: 100%;
      top: -100px;
    }
    .crowdfunding-bottom-banner img {
      width: 100%;
    }
    .crowdfunding-bottom-banner .close-button-wrap {
      z-index: 1100;
      position: absolute;
      top: -26px;
      left: 0px;
      transform: translateY(0px);
      transition: .2s;
    }
    .crowdfunding-bottom-banner .close-button-wrap .close-button {
      display: inline-block;
      padding: 4px 16px 0px 16px;
      background: #A8ABA6;
      border-radius: 0px 4px 0px 0px;
      pointer-events: auto;
    }
    .crowdfunding-bottom-banner .close-button-wrap.close .close-button {
      transform: translateY(var(--move-distance));
      transition: .2s;
      background-color: #EBAE1D;
    }
    .crowdfunding-bottom-banner .crowdfunding-bottom-banner-content {
      filter: drop-shadow(0 -2px 16px #40444830);
      font-size: 0;
      line-height: 0;
      border-top: 2px solid #A8ABA6;
      background: #EEE;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    .crowdfunding-bottom-banner .crowdfunding-bottom-banner-content.close {
      background: #FFFFFF00;
      border-top: none;
      border-bottom: 2px solid #EBAE1D;
    }
    .crowdfunding-bottom-banner .crowdfunding-bottom-banner-content img {
      visibility: visible;
      opacity: 1;
      transition: .2s;
      pointer-events: auto;
      width: 100%;
      max-width: 728px;
    }
    .crowdfunding-bottom-banner .crowdfunding-bottom-banner-content img.banner-image.pc {
      display: block;
    }
    .crowdfunding-bottom-banner .crowdfunding-bottom-banner-content img.banner-image.sp {
      display: none;
    }
    @media screen and (max-width: 600px) {
      .crowdfunding-bottom-banner .crowdfunding-bottom-banner-content img.banner-image.pc {
        display: none;
      }
      .crowdfunding-bottom-banner .crowdfunding-bottom-banner-content img.banner-image.sp {
        display: block;
      }
    }
    .crowdfunding-bottom-banner .crowdfunding-bottom-banner-content.close img {
      visibility: hidden;
      opacity: 0;
      transition: .2s;
      pointer-events: none;
    }
    `
  constructor() {
    super();
    this.isOpen = sessionStorage.getItem('crowdfunding-bottom-banner') === 'false' ? false : true;
    this.attachShadow({ mode: "open" });
    this.displayBanner();
  }
  gaTracking(eventName) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: eventName,
    })
  }
  changeIsOpen() {
    console.log('changeIsOpen');
    this.isOpen = !this.isOpen;
    sessionStorage.setItem('crowdfunding-bottom-banner', this.isOpen);
  }
  imageLoad() {
    const imageElements = this.shadowRoot.querySelectorAll('.banner-image');
    this.bannerHeight = imageElements[0].clientHeight || imageElements[1].clientHeight;
  }
  clickBanner() {
    console.log('click_crowdfunding_bottom_banner');
    this.gaTracking('click_crowdfunding_bottom_banner');
  }
  displayBanner() {
    console.log('display_crowdfunding_bottom_banner');
    this.gaTracking('display_crowdfunding_bottom_banner');
  }
  updated() {
    if (this.isOpen) {
      this.cookiePopupHeight = this.shadowRoot.querySelector('.cookie-popup').clientHeight;
    }
    else {
      this.cookiePopupHeight = this.shadowRoot.querySelector('.cookie-popup').clientHeight - this.bannerHeight;
    }
  }
  render() {
    return html`
      <div class="cookie-popup" style="top: ${- this.cookiePopupHeight}px">
        <cookie-popup></cookie-popup>
      </div>
      <div class="crowdfunding-bottom-banner">
        <div class="close-button-wrap ${this.isOpen ? '' : 'close'}">
          <div class="close-button" @click="${this.changeIsOpen}" style="--move-distance: ${this.bannerHeight}px;">
            ${this.isOpen ? html`
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8 12.4911L0 4.49113L1.49113 3L8 9.50887L14.5089 3L16 4.49113L8 12.4911Z" fill="white"/>
              </svg>
              ` : html`
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8 0.508903L16 8.50887L14.5089 10L8 3.49113L1.49113 10L1.30359e-07 8.50887L8 0.508903Z" fill="white"/>
              </svg>
            `}
          </div>
        </div>
        <div class="crowdfunding-bottom-banner-content ${this.isOpen ? '' : 'close'}">
          <a target="_blank" rel="noopener noreferrer" href="https://camp-fire.jp/projects/765818/view" @click="${this.clickBanner}">
            <img src="/images/crowdfunding-bottom-banner-2.jpeg" class="banner-image pc" @load="${this.imageLoad}">
            <img src="/images/crowdfunding-bottom-banner-1.jpeg" class="banner-image sp" @load="${this.imageLoad}">
          </a>
        </div>
      </div>
    `;
  }
}
customElements.define('crowdfunding-bottom-banner', CrowdfundingBottomBanner);